import React from 'react';
import ReactDOM from 'react-dom/client';
import EmbeddedAssistantChat from "./components/EmbeddedAssistantChat/EmbeddedAssistantChat";
import reportWebVitals from './reportWebVitals';
import {createTheme, ThemeProvider} from '@mui/material/styles';
import './index.css';

const theme = createTheme({
    typography: {
        fontFamily:
            "Inter, sans-serif",
        h3: {
            fontSize: "19px",
            fontWeight: "600",
            lineHeight: "24px",
            color: "#211F26"
        },
        body1: {
            fontSize: "16px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#211F26"
        },
        body2: {
            fontSize: "15px",
            fontWeight: "400",
            lineHeight: "24px",
            color: "#65636D"
        }
    },
});

function init(config) {
    const { containerId, agentId, debugMode } = config;

    // Render the app into the specified container element
    const rootElement = document.getElementById(containerId);

    if (rootElement) {
        const root = ReactDOM.createRoot(rootElement);
        root.render(
            <ThemeProvider theme={theme}>
                <EmbeddedAssistantChat
                    agentId={agentId}
                    debugMode={debugMode}
                />
            </ThemeProvider>
        );
    }
    else {
        console.error(`Container element with id '${containerId}' not found.`);
    }

    // Report web vitals
    reportWebVitals();
}

if (!window.LocusiveAssistant) {
    window.LocusiveAssistant = {};
}

// window.LocusiveAssistant.init = init;

/*!
  ╔════════════════════════════════════════════════════════════╗
  ╠═ Locusive helps SaaS companies provide their users with   ═╣
  ╠═ a better customer experience by automating many of the   ═╣
  ╠═ customer support and customer success tasks that they    ═╣
  ╠═ used to have to do manually. For your own AI agent,      ═╣
  ╠═ visit us at https://www.locusive.com or email            ═╣
  ╠═ info@locusive.com.                                       ═╣
  ╚════════════════════════════════════════════════════════════╝
 */

  (function() {

    // Note: these constants are redefined in Constants.js because we cannot import them in that file from this location
    const CloseChatMessageWindow = "closeChatMessageWindow";
    const AgentIsValidMessage = "agentIsValid";

    if (window.locusiveAssistantInitialized) {
        return;
    }

    window.locusiveAssistantInitialized = true;

    function correctMissingMetaport() {
        // Check if a viewport meta tag already exists
        let metaViewport = document.querySelector('meta[name="viewport"]');

        if (!metaViewport) {
            // Create a new meta element
            metaViewport = document.createElement('meta');
            metaViewport.name = "viewport";
            metaViewport.content = "width=device-width, initial-scale=1.0";
            document.head.appendChild(metaViewport);
        }
    }

    correctMissingMetaport();

    const StartElementType = {
        Tab: 'tab',
        Button: 'button'
    };
    const isLocalhost = ['localhost', '127.0.0.1', '192.168.0.1'].includes(window.location.hostname) && !window.location.href.includes("test_prod.html")
    const assistantBundleUrl = isLocalhost
        ? "http://localhost:8093/locusive-assistant.bundle.js"
        : "https://embedded-assistant.locusive.com/locusive-assistant.bundle.js";

    let chatMessageWindowVisible = false;
    let startElement;

    /**
     * Function to wait for DOMContentLoaded event
     */
    function waitForDomContentLoaded() {
        return new Promise((resolve) => {
            if (document.readyState === 'loading') {
                document.addEventListener('DOMContentLoaded', resolve);
            }
            else {
                // If DOMContentLoaded has already fired
                resolve();
            }
        });
    }

    function createChatButton() {
        const chatButton = document.createElement('div');
        chatButton.classList.add('locusive-chat-button');

        // Image - Locusive logo
        const image = document.createElement('img');
        image.src = 'https://storage.googleapis.com/locusive-resource-webapp/icon-small.png';
        image.alt = '';
        image.className = 'locusive-logo';

        chatButton.appendChild(image);
        return chatButton;
    }

    function createTab() {
        const tab = document.createElement('div');
        tab.classList.add('locusive-tab');

        // Handle key events for accessibility
        tab.addEventListener('keydown', function (event) {
            if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault();
                tab.click();
            }
        });

        const image = document.createElement('img');
        image.src = 'https://storage.googleapis.com/locusive-resource-webapp/locusive-logo-white.png';
        image.alt = 'Locusive logo tab';
        image.className = 'locusive-logo';
        tab.appendChild(image);

        const text = document.createElement('span');
        text.textContent = 'Ask Anything';
        tab.appendChild(text);

        return tab;
    }

    // Console Banner Function
    // The end bars are purposefully misaligned below because when you view the banner in the console they become aligned properly, do not change them
    function showConsoleBanner() {
        const banner = `
╔═══════════════════════════════════════════════════════════════════════╗
╠═      _                         _                                    ═╣
╠═     | |    ___   ___ _   _ ___(_)_   _____                          ═╣
╠═     | |   / _ \\ / __| | | / __| \\ \\ / / _ \\                         ═╣
╠═     | |__| (_) | (__| |_| \\__ \\ |\\ V /  __/                         ═╣
╠═     |_____\\___/ \\___|\\__,_|___/_| \\_/ \\___|                         ═╣
╠═                                                                     ═╣
╠═     Looking for your own AI assistant to help with customer         ═╣ 
╠═     experience? Check out Locusive at https://www.locusive.com.     ═╣
╠═                                                                     ═╣
╚═══════════════════════════════════════════════════════════════════════╝
`;
        console.log('%c ' + banner, 'color: #D642B5;');
    }

    // Show the console banner
    showConsoleBanner();

    /**
     * Function to create and append the chat button or tab
     */
    function createStartElement(config) {
        let startElement;

        switch (config.startElement) {
            case StartElementType.Button: startElement = createChatButton(); break;
            case StartElementType.Tab:
            default: startElement = createTab(); break;
        }

        startElement.classList.add('locusive-start-element');
        startElement.classList.add('locusive-hidden');  // Start hidden until agent ID is validated
        startElement.setAttribute('role', 'button');
        startElement.setAttribute('aria-label', 'Open chat assistant');
        startElement.tabIndex = 0;

        // Handle key events for accessibility
        startElement.addEventListener('keydown', function (event) {
            if (event.key === 'Enter' || event.key === ' ') {
                event.preventDefault();
                startElement.click();
            }
        });

        document.body.appendChild(startElement);
        return startElement;
    }

    function getQueryParams() {
        return Object.fromEntries(new URLSearchParams(window.location.search));
    }

    /**
     * Function to get configuration
     */
    function getConfig() {
        const queryParams = getQueryParams();

        const defaultConfig = {
            agentId: '',
            startElement: StartElementType.Tab,
            debugMode: queryParams.debug
        };

        return Object.assign({}, defaultConfig, window.locusiveAssistantConfig || {});
    }

    function toggleAssistantVisibility() {
        chatMessageWindowVisible = !chatMessageWindowVisible;
        const assistantContainer = document.getElementById('locusive-assistant-container');
        if (chatMessageWindowVisible) {
            assistantContainer.classList.remove('locusive-hidden');
            assistantContainer.classList.add('locusive-visible');
        }
        else {
            assistantContainer.classList.remove('locusive-visible');
            assistantContainer.classList.add('locusive-hidden');
        }
    }

    function toggleStartElementVisibility() {
        startElement.classList.toggle('locusive-hidden');
    }

    /**
     * Function to initialize the assistant
     */
    function initAssistant() {
        const config = getConfig();
        startElement = createStartElement(config);

        // Create a container element for the assistant
        const containerId = 'locusive-assistant-container';
        const assistantContainer = document.createElement('div');
        assistantContainer.id = containerId;
        assistantContainer.className = 'locusive-assistant-container locusive-hidden';
        document.body.appendChild(assistantContainer);

        // Load the bundled React app
            // The LocusiveAssistant object is set on the window by the code produced by the build script and calls the "init" method in the EmbeddedAssistant's index.js file
            if (window.LocusiveAssistant ) {
                init({
                    containerId: containerId,
                    agentId: config.agentId,
                    debugMode: config.debugMode || false,
                });

                // Once the assistant is initialized, show the start element
                startElement.classList.remove('locusive-hidden');
            }
            else {
                console.error('LocusiveAssistant is not available.');
            }

        // Event listener for the start element click
        startElement.addEventListener('click', () => {
            toggleAssistantVisibility();
            if (config.startElement === StartElementType.Tab) {
                toggleStartElementVisibility();
            }
        });

        // Listen for messages from the assistant
        window.addEventListener('LocusiveAssistantEvent', function(event) {
            const data = event.detail;

            if (data && data.action) {
                switch (data.action) {
                    case CloseChatMessageWindow:
                        if (chatMessageWindowVisible) {
                            toggleAssistantVisibility();
                        }

                        if (config.startElement === StartElementType.Tab) {
                            toggleStartElementVisibility();
                        }
                        break;

                    case AgentIsValidMessage:
                        startElement.classList.remove('locusive-hidden');
                        break;

                    default:
                        break;
                }
            }
        });
    }

    /**
     * Wait for both CSS to load and DOMContentLoaded before initializing
     */
    Promise.all([waitForDomContentLoaded()])
        .then(() => {
            initAssistant();
        })
        .catch((error) => {
            console.error(error);
        });

})();


export { init };


