import mixpanel from 'mixpanel-browser';

if (process.env.NODE_ENV === 'production') {
    mixpanel.init("3cae6aff47588a77d307e50dfce064fe", { debug: true, track_pageview: true, persistence: 'localStorage' })
}

class EventLogger {
    static sendMessage = (agentId, message, url) => {
        if (process.env.NODE_ENV === 'production') {
            try {
                mixpanel.track('Web Embedded Assistant: Send Message', {
                    'agentId': agentId,
                    'message': message,
                    'url': url
                });
            }
            catch (e) {
                console.error(e);
            }
        }
    }

    static clickOnRecommendation = (agentId, contextualRecommendationId, url) => {
        if (process.env.NODE_ENV === 'production') {
            try {
                mixpanel.track('Web Embedded Assistant: Contextual Recommendation', {
                    'agentId': agentId,
                    'contextualRecommendationId': contextualRecommendationId,
                    'url': url
                });
            }
            catch (e) {
                console.error(e);
            }
        }
    }
}

export default EventLogger;